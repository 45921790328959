@keyframes btn-rdv-anim {
  0%{
    transform: translateY(50%);
    opacity: 0.0;
  }
  60%{
    transform: translateY(50%);
    opacity: 0.0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}
.btn-rdv{
  animation: btn-rdv-anim 4s ease-out  ;
}

@keyframes notification {
  0%{
    scale: 0.2;
    opacity: 0;
  }
  95%{
    scale: 0.8;
    opacity: 0.0;
  }
  100%{
    scale: 1;
    opacity: 1;
  }
}
.notification{
  animation: notification 5s ease-out ;
}

@keyframes hero-img-anim {
  from{
    transform: translateX(100%);
  }
  to{
    transform: translateX(0);
  }
}
.hero-img{
  animation:hero-img-anim 2s ease-out ;
}

/* animations du texte de la hero section */

@keyframes text-part-1-anim {
  from{
    opacity:0;
    transform:translateY(50px);
  }
  to {
    opacity:1;
    transform:translateY(0%);
  }
}
@keyframes text-part-2-anim {
  0%{
    opacity:0;
    transform:translateY(50px);
  }
  50%{
    opacity:0;
    transform:translateY(25px);
  }
 100% {
    opacity:1;
    transform:translateY(0%);
  }
}
@keyframes text-part-3-anim {
  0%{
    opacity:0;
    transform:translateY(50px);
  }
  60%{
    opacity:0;
    transform:translateY(25px);
  }
 100% {
    opacity:1;
    transform:translateY(0%);
  }
}
@keyframes text-part-4-anim {
  0%{
    opacity:0;
    transform:translateY(50px);
  }
  70%{
    opacity:0;
    transform:translateY(25px);
  }
 100% {
    opacity:1;
    transform:translateY(0%);
  }
}
@keyframes text-part-5-anim {
  0%{
    opacity:0;
    transform:translateY(50px);
  }
  80%{
    opacity:0;
    transform:translateY(25px);
  }
 100% {
    opacity:1;
    transform:translateY(0%);
  }
}
.text-part-1{
  display: inline-block;
  animation: text-part-1-anim 1.5s ease-out;
}
.text-part-2{
  display: inline-block;
  animation: text-part-2-anim 3s ease-out;
}
.text-part-3{
  display: inline-block;
  animation: text-part-3-anim  4.5s ease-out;
}
.text-part-4{
  display: inline-block;
  animation: text-part-4-anim 6s ease-out;
}
.text-part-5{
  display: inline-block;
  animation: text-part-5-anim 7.5s ease-out;
}
