@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@800&family=Inter&family=Poppins&family=Roboto&family=Style+Script&display=swap');


@font-face {
  font-family: 'Nexa Bold';
  src: url('./fonts/Nexa\ Bold.otf')format(opentype);
}
@font-face {
  font-family: 'Nexa Light';
  src: url('./fonts/Nexa\ Light.otf')format(opentype);
}
@font-face {
  font-family: 'Nexa Regular';
  src: url('./fonts/Nexa\ Regular.otf')format(opentype);
}


